import React, { useRef, useState, useEffect } from 'react'
import { login, isAuthenticated } from '../utils/auth'
import { Pane } from 'evergreen-ui'
import { createUseStyles } from 'react-jss'
import {
  navStyles,
  customListStyles,
  tableSectionStyles,
  generalStyles,
  sidesheetStyles,
  formStyles
} from '../styles/sharedComponentStyles'
import CustomTable from '../components/CustomTable'
import { gridOptions } from '../gridConfig/dataExportGridConfig'
import { useHandleDataExportsList } from '../hooks/useHandleDataExportsList'
import { useRoleAccess } from '../hooks/useRoleAccess'
import ExportCSVModal from '../components/exportCSV'
import { Button } from 'evergreen-ui'
import { navigate } from 'gatsby'
import { useGlobalStore } from '../hooks/useGlobalStore'

const useSharedStyles = createUseStyles({
  ...generalStyles,
  ...navStyles,
  ...customListStyles,
  ...tableSectionStyles,
  ...sidesheetStyles,
  ...formStyles,
  dataExportTable: {
    height: '100vh',
    '& .ag-row .ag-cell': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center'
    }
  }
})

const DataExport = () => {
  const sharedClasses = useSharedStyles()
  const dataExportTable = useRef(null)
  const { dataExportSet, onGridReady, onFilterChanged } =
    useHandleDataExportsList({ dataExportTable })

  const { canExportData } = useRoleAccess()
  const { setRealmPath } = useGlobalStore()
  const [showExport, setShowExport] = useState(false)

  const handleCloseExportModal = () =>  {
    setShowExport(false)
    window.location.reload();
  }

  useEffect(() => {
    if (!canExportData) {
      navigate(`/studies`)
    }
    setRealmPath('data-export')
  }, [canExportData, setRealmPath])

  if (!isAuthenticated()) {
    login()
    return (
      <Pane
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <h2>Redirecting to login...</h2>
      </Pane>
    )
  }

  return (
    <>
      <nav className={sharedClasses.nav}>
        <Pane className={sharedClasses.secondaryNav}>
          <h1 data-testid="main-title">Data Export</h1>
          <Pane className={sharedClasses.autoComplete} display="flex">
            {canExportData && (
              <Button
                className={sharedClasses.buttonStyles}
                onClick={() => setShowExport(true)}
              >
                Launch New Data Export
              </Button>
            )}
          </Pane>
        </Pane>
      </nav>
      <Pane className={sharedClasses.tableContainer} is="section">
        <Pane ref={dataExportTable}>
          <CustomTable
            gridOptions={gridOptions}
            defaultColDef={{
              sortable: true,
              filter: true,
              floatingFilter: true,
              suppressMenu: true,
              minWidth: 100,
              tooltipValueGetter: params => params.value,
              flex: 2
            }}
            overlayLoadingTemplate={
              '<span style="position: absolute; top: 150px; font-size: 20px" className="ag-overlay-loading-center">Please wait while your rows are loading...</span>'
            }
            overlayNoRowsTemplate={
              '<span style="position: absolute; top: 150px; font-size: 20px" className="ag-overlay-loading-center">No data export jobs were found. Jobs are retained for 30 days.</span>'
            }
            onGridReady={onGridReady}
            rowHeight={70}
            onFilterChanged={onFilterChanged}
            tableStyleClasses={[sharedClasses.dataExportTable]}
            data={dataExportSet.filter(({ dummyObj }) => !dummyObj)}
          />
        </Pane>
      </Pane>

      {showExport && (
        <ExportCSVModal
          showExport={showExport}
          handleCloseModal={handleCloseExportModal}
        />
      )}
    </>
  )
}

export default DataExport
